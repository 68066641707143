const Home = () => import('@theme-views/public/pages/Home')
const Login = () => import('@theme-views/public/pages/Login')
const Faq = () => import('@theme-views/public/pages/Faq')
const Support = () => import('@theme-views/public/pages/Support')
const CookiePolicy = () => import('@theme-views/public/pages/CookiePolicy')
const NotFound = () => import('@theme-views/public/pages/NotFound')
const CustomPage = () => import('@theme-views/public/pages/CustomPage')

const ExclusivePromotion = () => import('@theme-views/public/pages/ExclusivePromotion')
const LogoutPromotion = () => import('@theme-views/public/pages/LogoutPromotion')
const DeleteAccountPromotion = () => import('@theme-views/public/pages/DeleteAccountPromotion')
const BlockedRegister = () => import('@theme-views/public/pages/BlockedRegister')
const FailedOauth = () => import('@theme-views/public/pages/FailedOauth')

const FakeUserHome = () => import('@theme-views/user/pages/Home/FakeHome')
const UserHome = () => import('@theme-views/user/pages/Home')
const UserProfile = () => import('@theme-views/user/pages/Profile')
const ProfileShowcase = () => import('@theme-views/user/pages/Profile/profile-showcase')
const UserOtherProfile = () => import('@theme-views/user/pages/OtherProfile')
const UserPhotos = () => import('@theme-views/user/pages/Photos')
const UserMessages = () => import('@theme-views/user/pages/Messages')
const UserMessagesBin = () => import('@theme-views/user/pages/MessagesBin')
const UserMessagesChat = () => import('@theme-views/user/pages/MessagesChat')
const UserVisits = () => import('@theme-views/user/pages/Visits')
const UserFavorites = () => import('@theme-views/user/pages/Favorites')
const UserOtherFavorites = () => import('@theme-views/user/pages/OtherFavorites')
const UserSearchProfiles = () => import('@theme-views/user/pages/SearchProfiles')
const UserSearchResults = () => import('@theme-views/user/pages/SearchResults')
const UserSettings = () => import('@theme-views/user/pages/Settings')
const UserDisableNotifications = () => import('@theme-views/user/pages/DisableNotifications')
const StopNotifications = () => import('@theme-views/public/pages/StopNotifications')
const UserSubscriptionCredits = () => import('@theme-views/user/pages/SubscriptionCredits')
const UserSubscriptionCreditsPayment = () => import('@theme-views/user/pages/SubscriptionCreditsPayment')
const UserSubscriptionPremiumPayment = () => import('@theme-views/user/pages/SubscriptionPremiumPayment')
const UserPremium = () => import('@theme-views/user/pages/Premium')
const UserHelp = () => import('@theme-views/user/pages/Help')

import Vue from 'vue'
const $t = Vue.i18n.translate

let routes = [
	{
		name: 'home',
		component: Home,
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'login',
		component: Login,
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'faq',
		component: Faq,
		meta: {
			layout: 'public'
		}
	},
  {
    name: 'cookie-policy',
    component: CookiePolicy,
    meta: {
      layout: 'public'
    }
  },
	{
		name: 'contact',
		component: Support, //should've been for contact component
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'support',
		component: Support,
		meta: {
			layout: 'public'
		}
	},
	{
    path: '/dashboard',
		name: 'user-home',
		component: UserHome,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-mobile-home',
		component: ProfileShowcase,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-activate-profile',
		component: FakeUserHome,
		props: {
            showType: 'activation'
		},
		meta: {
			layout: 'public'
		}
	},
    {
        name: 'complete-register',
        component: FakeUserHome,
        props: {
            showType: 'complete_register'
        },
        meta: {
            layout: 'public'
        }
    },
    {
        name: 'boost-profile',
        component: FakeUserHome,
        props: {
            showType: 'boost_profile'
        },
        meta: {
            layout: 'public'
        }
    },
    {
        name: 'claim-daily-icebreakers',
        component: FakeUserHome,
        props: {
            showType: 'claim_daily_icebreakers'
        },
        meta: {
            layout: 'public'
        }
    },
	{
		name: 'user-profile',
		component: UserProfile,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-other-profile',
		component: UserOtherProfile,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-photos',
		component: UserPhotos,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-messages',
		component: UserMessages,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-messages-bin',
		component: UserMessagesBin,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-messages-chat',
		component: UserMessagesChat,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-visits',
		component: UserVisits,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-favorites',
		component: UserFavorites,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-other-favorites',
		component: UserOtherFavorites,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-search-profiles',
		component: UserSearchProfiles,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-search-results',
		component: UserSearchResults,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-settings',
		component: UserSettings,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		name: 'user-credits',
		component: UserSubscriptionCredits,
		meta: {
			layout: 'user-subscription'
		}
	},
	{
		new_route: true,
		path: $t('/credits/:id/pay'),
		name: 'user-credits-payment',
		component: UserSubscriptionCreditsPayment,
		meta: {
			layout: 'user-subscription'
		}
	},
	{
		name: 'user-premium',
		component: UserPremium,
		meta: {
			layout: 'user-subscription'
		}
	},
	{
		new_route: true,
		path: $t('/premium/:id/pay'),
		name: 'user-premium-payment',
		component: UserSubscriptionPremiumPayment,
		meta: {
			layout: 'user-subscription'
		}
	},
	{
		name: 'exclusive-promotion',
		component: ExclusivePromotion,
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'logged-out',
		component: LogoutPromotion,
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'user-disable-notifications',
		component: UserDisableNotifications,
		meta: {
			layout: 'public'
		}
	},
    {
        name: 'user-stop-notifications',
        component: StopNotifications,
        meta: {
            layout: 'public'
        }
    },
	{
		name: 'account-deleted',
		component: DeleteAccountPromotion,
		meta: {
			layout: 'public'
		}
	},
	{
		new_route: true,
		path: $t('/help'),
		name: 'user-help',
		component: UserHelp,
		meta: {
			layout: 'user-dashboard'
		}
	},
	{
		path: '/terms-and-conditions',
		name: 'terms',
		component: CustomPage,
		props: (route) => ({ page_name: route.path.substring(1) }),
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'blocked-register',
		component: BlockedRegister,
		meta: {
			layout: 'public'
		}
	},
	{
		name: 'failed-oauth',
		component: FailedOauth,
		meta: {
			layout: 'public'
		}
	},
	{
		path: '*',
    name: 'custom',
		component: CustomPage,
		props: (route) => ({ page_name: route.path.substring(1) }),
		meta: {
			layout: 'public'
		}
  },
]

export function templateCustomBeforeEach() {
	setTimeout(() => {
			window.scrollTo(0, 0);
	}, 86);
}


export default {
	routes
}
